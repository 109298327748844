import Vue from "vue"
export default {
  namespaced: true,
  state: {
    offerTypes: [],
    currencies: [],
  },
  getters: {
    offerTypes: (state) => {
      return state.offerTypes
    },
    offerType: (state) => (id) => {
      return state.offerTypes.find((x) => x.id == id)
    },
    currencies: (state) => {
      return state.currencies
    },
    currenciy: (state) => (id) => {
      return state.currencies.find((x) => x.id == id)
    },
  },
  actions: {
    async init(ctx) {
      ctx.commit("init")
    },
  },
  mutations: {
    async init(state) {
      Vue.prototype.$axios.get("/dictionary/offer-type/all").then((res) => {
        state.offerTypes = res.data
      })
      Vue.prototype.$axios.get("/dictionary/currency/all").then((res) => {
        state.currencies = res.data
      })
      console.log("ctx.dictionaries: initialized")
    },
  },
}
